import React, { useEffect } from "react";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Header from "./components/Header";
import "./worklog.scss";
import Filters from "./components/Filters";
import History from "./components/History";
import { useResponsive } from "../../../hooks/useResponsive";
import { useBoards } from "../../../utils/reactQuery/boards";
import { setActiveBoards } from "../../../redux/ActiveBoardSlice";
import { useCustomSearchParams } from "../../../hooks/useCustomSearchParams";
import { addAllBoard } from "../../../redux/SearchSlice";

function Worklog() {
  const isMobile = useResponsive("down", "md");
  const dispatch = useDispatch();
  const { boards } = useBoards();
  const [Oldboard, updateParams] = useCustomSearchParams("board");
  const activeBoard = useSelector((state) => state.activeBoard.activeBoardList);

  useEffect(() => {
    if (!Oldboard) {
      if (boards) {
        dispatch(
          setActiveBoards(
            boards?.data?.data?.responseData
              .filter((el) => !el.is_archive)
              .sort(function (a, b) {
                return a.board_name.localeCompare(b.board_name);
              }) || []
          )
        );

        updateParams(
          "board",
          activeBoard.map((el) => el.board_id)
        );
      }
      dispatch(addAllBoard(activeBoard.map((el) => el.board_id)));
    }
  }, [boards]);

  return (
    <>
      <Header />
      {!isMobile && (
        <>
          <Divider />
          <Filters />
        </>
      )}
      <History />
    </>
  );
}

export default Worklog;
