import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, InputAdornment, Stack, Typography } from "@mui/material";

import { adminSettingLDAPSettingsSchemas } from "../../../schemas/adminSettingPage";
import RHFTextField from "../../../components/RFH/RFHtextfield";
import useMutate from "../../../hooks/useMutate";
import { updateTenantLDAP } from "../../../sevices/apiSystemSettings";
import { useResponsive } from "../../../hooks/useResponsive";

function LDAPSettingsBindCred({ initialData }) {
  const [loading, setLoading] = useState(false);
  const { mutate } = useMutate(updateTenantLDAP, [], () => setLoading(false));
  const methods = useForm({
    resolver: yupResolver(adminSettingLDAPSettingsSchemas),
    defaultValues: initialData,
  });
  const isMobile = useResponsive("down", "md");
  // Watch form values for real-time changes (optional)
  const formValues = methods.watch();

  // Function to check if any field has a value
  const isAnyFieldFilled = () => {
    return Object.values(formValues).some(
      (value) => value !== undefined && value !== "" && value !== null
    );
  };

  const onSubmit = (formData) => {
    setLoading(true);
    mutate(formData);
  };

  return (
    <>
      <FormProvider {...methods}>
        <div className="mt-32 form-section  ">
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div>
              <Typography className="setting-heading mb-2  ml-0">
                LDAP Setting
              </Typography>
              <div className="mb-4 ldap-note">
                <Typography className="logo-note mb-2">
                  If your team uses an LDAP server, you can enter the LDAP
                  server settings below for user authentication. Please note:
                </Typography>
                <ul>
                  <li className="logo-note">
                    All LDAP users must still be added to the system to access
                    it.
                  </li>
                  <li className="logo-note">
                    External users can also be invited using their email
                    addresses by adding them as regular users.
                  </li>
                </ul>
              </div>

              <Stack
                mt={3}
                direction={isMobile ? "column" : "row"}
                spacing={2.5}>
                <RHFTextField
                  name="ldap_url"
                  label="LDAP URL"
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    backgroundColor: "#fff",
                  }}
                />
                <RHFTextField
                  name="ldap_base_dn"
                  label="Search Base"
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    backgroundColor: "#fff",
                  }}
                />
              </Stack>
            </div>

            <div className="mt-32">
              <Typography className="setting-heading mb-4 ml-0">
                Bind Credentials
              </Typography>
              <Stack direction={isMobile ? "column" : "row"} spacing={2.5}>
                <RHFTextField
                  name="ldap_user"
                  label="Bind DN"
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    backgroundColor: "#fff",
                  }}
                />
                <RHFTextField
                  name="ldap_password"
                  label="Password"
                  type={"password"}
                  sx={{
                    width: isMobile ? "100%" : "50%",
                    backgroundColor: "#fff",
                  }}
                />
              </Stack>
            </div>
            <Stack alignItems="end" className="mt-32">
              <Button
                variant="contained"
                type="submit"
                sx={{ padding: "8px 32px" }}
                className="theme-bg-color"
                disabled={loading || !isAnyFieldFilled()}>
                Update
              </Button>
            </Stack>
          </form>
        </div>
      </FormProvider>
    </>
  );
}

export default LDAPSettingsBindCred;
