import React, { useState } from "react";
import {
  Button,
  FormControl,
  InputAdornment,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import CheckBox from "../../../../styles/svg/id-icon.svg";
import { useResponsive } from "../../../../hooks/useResponsive";

function FieldCreateEditCard({
  handleKeyDown,
  setTitle,
  setCreateBtnOpen,
  edit = false,
  value,
  minRows = 3,
  className = "create-card-textarea",
  ref = null,
  setOpenCreateSubtask,
}) {
  const [error, setError] = useState(false);
  const isMobile = useResponsive("down", "md");

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setTitle(value);
    if (value.length <= 255) {
      setError(false);
    } else {
      setError(true);
    }
  };

  return (
    <FormControl fullWidth onClick={(e) => e.stopPropagation()} ref={ref}>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="What needs to be done?"
        minRows={minRows} // Set the minimum number of rows
        className={`${className} ${error ? "create-card-error-textarea" : ""}`}
        // defaultValue={value}
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onBlur={
          isMobile
            ? () => {
                "";
              }
            : setCreateBtnOpen
        }
        autoFocus
        ref={ref}
      />

      {!edit && (
        <InputAdornment position="end">
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}>
            <Stack direction={"row"}>
              <img src={CheckBox} alt="checkbox" className=" mb-6" />
              <ExpandMoreIcon
                className="mb-6"
                onClick={() => setOpenCreateSubtask(false)}
              />
            </Stack>
            {isMobile && (
              <Button
                className="mb-6 mr-2"
                sx={{
                  color: "white",
                  backgroundColor: "rgb(37, 150, 190)",
                  padding: "0px",
                  fontSize: "12px",
                }}
                onClick={(event) => {
                  handleKeyDown({ ...event, key: "btnClicked" });
                }}>
                Done
              </Button>
            )}
          </Stack>
        </InputAdornment>
      )}
      {error && (
        <Typography
          variant="body2"
          color="error"
          className="card-error-font-size">
          Character limit exceeded! Maximum 255 characters allowed.
        </Typography>
      )}
    </FormControl>
  );
}

export default FieldCreateEditCard;
