import { useQuery } from "@tanstack/react-query";
import {
  getUser,
  getUsers,
  getWatchlist,
  getWatchlistUsers,
  getLdapUserList,
  getUserDetailByToken,
} from "../../sevices/apiUser";
import { getBoardUsers } from "../../sevices/apiBoards";

export function useUsers() {
  const {
    isLoading,
    data: users,
    error,
    isError,
  } = useQuery({
    queryKey: ["users"],
    queryFn: getUsers,
  });

  return { isLoading, error, users, isError };
}

export function useBoardUsers(id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: [`boardUsers`, id],
    queryFn: () => getBoardUsers(id),
    enabled: !!id
  });

  return { isLoading, error, data, isError };
}

export function useLoginUser(id) {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: ["me"],
    queryFn: () => getUser(id),
  });
  return { isLoading, error, data, isError };
}

export function useInternalLdapUser() {
  const { isLoading, data, error, isError } = useQuery({
    queryKey: ["LDAPinternalList"],
    queryFn: () => getLdapUserList(),
  });
  return { isLoading, error, data, isError };
}
export function useWatching(id) {
  const { isLoading, data, error, isError, isRefetching } = useQuery({
    queryKey: ["watching", id],
    queryFn: () => getWatchlist(id),
    enabled: !!id,
  });
  return { data, isLoading, error, isError, isRefetching };
}

export function useWatchlistUsers() {
  const { isLoading, data, error, isError, isRefetching } = useQuery({
    queryKey: ["watchlistUsers"],
    queryFn: () => getWatchlistUsers(),
  });
  return { data, isLoading, error, isError, isRefetching };
}

export function useUserData(token) {
  const { isLoading, data, error, isError, isRefetching } = useQuery({
    queryKey: ["user-data"],
    queryFn: () => getUserDetailByToken(token),
    enabled: !!token,
    retry: false
  });
  return { data, isLoading, error, isError, isRefetching };
}