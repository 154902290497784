import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import Badge from "@mui/material/Badge";

import AdminLogo from "../../styles/svg/avtar-admin-icon.svg";
import { useResponsive } from "../../hooks/useResponsive";
import { getAPIBaseUrl } from "../../utils/constants";

export default function Default({
  title,
  username,
  user_image,
  className,
  children,
  unassigned = false,
  isAdmin = false,
  onClick,
  style = {},
}) {
  // console.log("getAPIBaseUrl", getAPIBaseUrl);

  const getUrl = getAPIBaseUrl();

  const isMobile = useResponsive("down", "md");
  // const convertedImage = unassigned ? user_image : getUrl + user_image;
  const convertedImage = unassigned ? user_image : getUrl + "/" + user_image;

  return (
    <>
      {isAdmin ? (
        <Tooltip title={`${title}`.trim()} onClick={onClick}>
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <img src={AdminLogo} alt="badge" width={isMobile ? "16px" : ""} />
            }
          >
            <Avatar
              alt={username}
              src={user_image && convertedImage}
              title=""
              className={` ${className} `}
              sx={
                user_image?.split("uploads/user/")?.[1]?.trim()
                  ? { bgcolor: "#fff", ...style }
                  : style
              }
            >
              {`${children}`.toUpperCase()}
            </Avatar>
          </Badge>
        </Tooltip>
      ) : (
        <Tooltip title={`${title}`.trim()} onClick={onClick}>
          <Avatar
            alt={username}
            src={user_image && convertedImage}
            title=""
            className={`${className} `}
            sx={
              user_image?.split("uploads/user/")?.[1]?.trim()
                ? { bgcolor: "#fff", ...style }
                : style
            }
          >
            {`${children}`.toUpperCase()}
          </Avatar>
        </Tooltip>
      )}
    </>
  );
}
