import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { localStorageInstance } from "../utils/localStorage";
// Calculate the end date (current date and time)
const endDate = moment().toISOString();

// Calculate the start date (30 days before the current date and time)
const startDate = moment().subtract(30, "days").toISOString();
const isGridView = localStorageInstance.getItem("showGridView");
const showGridView =
  isGridView === true ? true : isGridView === false ? false : true;

// Create the object
const dateRange = {
  startDate: startDate,
  endDate: endDate,
};

const initialState = {
  searchText: "",
  boardSearchText: "",
  boardSearch: [],
  epicSearch: [],
  dateRange: null,
  myTaskUser: [],
  taskTab: true,
  totalWorkHours: 0,
  gridView: showGridView,
};

const search = createSlice({
  name: "search",
  initialState,
  reducers: {
    searchCard: {
      prepare(searchText) {
        return {
          payload: { searchText },
        };
      },
      reducer(state, action) {
        state.searchText = `${action.payload.searchText}`.toLowerCase();
      },
    },
    searchBoard: {
      prepare(searchText) {
        return {
          payload: { searchText },
        };
      },
      reducer(state, action) {
        state.boardSearchText = `${action.payload.searchText}`.toLowerCase();
      },
    },
    addBoard: {
      prepare(boardSearch) {
        return {
          payload: { boardSearch },
        };
      },
      reducer(state, action) {
        state.epicSearch = [];
        const isAdd = state.boardSearch.includes(action.payload.boardSearch);
        !isAdd && state.boardSearch.push(action.payload.boardSearch);

        // state.boardSearch = action.payload.boardSearch;
      },
    },
    removeBoard: {
      prepare(boardSearch) {
        return {
          payload: { boardSearch },
        };
      },
      reducer(state, action) {
        state.epicSearch = [];
        const isAdd = state.boardSearch.includes(action.payload.boardSearch);
        isAdd &&
          (state.boardSearch = state.boardSearch.filter(
            (el) => el != action.payload.boardSearch
          ));
      },
    },
    addEpic: {
      prepare(epicSearch) {
        return {
          payload: { epicSearch },
        };
      },
      reducer(state, action) {
        const isAdd = state.epicSearch.includes(action.payload.epicSearch);
        isAdd
          ? (state.epicSearch = state.epicSearch.filter(
              (el) => el != action.payload.epicSearch
            ))
          : state.epicSearch.push(action.payload.epicSearch);
      },
    },
    addAllBoard: {
      prepare(boardSearch) {
        return {
          payload: { boardSearch },
        };
      },
      reducer(state, action) {
        state.epicSearch = [];
        state.boardSearch = action.payload.boardSearch;
      },
    },
    setDateRange: {
      prepare(dateRange) {
        return {
          payload: { dateRange },
        };
      },
      reducer(state, action) {
        state.dateRange = action.payload.dateRange;
      },
    },
    checkAndSetUser: {
      prepare(myTaskUser) {
        return {
          payload: { myTaskUser },
        };
      },
      reducer(state, action) {
        state.myTaskUser= [action.payload.myTaskUser];
      },
    },
    setMyTaskUser: {
      prepare(myTaskUser) {
        return {
          payload: { myTaskUser },
        };
      },
      reducer(state, action) {
        // state.myTaskUser = action.payload.myTaskUser;

        // const isAdd = state.myTaskUser.some(
        //   (el) => el.user_id == action.payload.myTaskUser.user_id
        // );
        // isAdd
        //   ? (state.myTaskUser = state.myTaskUser.filter(
        //       (el) => el.user_id != action.payload.myTaskUser.user_id
        //     ))
        //   : state.myTaskUser.push(action.payload.myTaskUser);
        state.myTaskUser = action.payload.myTaskUser;
      },
    },
    addAllEpic: {
      prepare(epicSearch) {
        return {
          payload: { epicSearch },
        };
      },
      reducer(state, action) {
        state.epicSearch = action.payload.epicSearch;
      },
    },
    setTaskTab: {
      prepare(tab) {
        return {
          payload: { tab },
        };
      },
      reducer(state, action) {
        state.taskTab = action.payload.tab;
      },
    },
    setGridView: {
      prepare(tab) {
        return {
          payload: { tab },
        };
      },
      reducer(state, action) {
        state.gridView = !state.gridView;
      },
    },
    setWorkHours: {
      prepare(hour) {
        return {
          payload: { hour },
        };
      },
      reducer(state, action) {
        state.totalWorkHours = action.payload.hour;
      },
    },
  },
});

export const {
  searchCard,
  searchBoard,
  addBoard,
  removeBoard,
  addAllBoard,
  setDateRange,
  setMyTaskUser,
  addAllEpic,
  addEpic,
  setTaskTab,
  setGridView,
  setWorkHours,
  checkAndSetUser,
} = search.actions;

export default search.reducer;

/*
export default function reducer(state = initialValues, action) {
  switch (action.type) {
    case "customer/createCustomer":
      return { ...state,name: action.payload.name , nationalId: action.payload.nationalId };
   
    default:
      return state;
  }
}

export const createAccount = function (name, nationalId) {
  return { type: "customer/createCustomer", payload: { name, nationalId } };
};
*/
export const conditionMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type === search.actions.addBoard.type) {
    const state = store.getState();
    const dataValue = state.search;

    // Define your condition here
    // if (dataValue > 10) {
    // store.dispatch(
    //   setUpdate("Updated because data value is greater than 10")
    // );
    // }
  }

  return result;
};
