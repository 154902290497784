import React, { useEffect } from "react";
import SelectUser from "./SelectUser";
import { localStorageInstance } from "../../../../utils/localStorage";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { checkAndSetUser } from "../../../../redux/SearchSlice";

function User() {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.users.logInUser)
  const myTaskUser = useSelector((state) => state.search.myTaskUser);
  useEffect(()=>{
    dispatch(checkAndSetUser(currentUser));
  },[currentUser])
  
  return <div>{myTaskUser && <SelectUser user={myTaskUser} />}</div>;
}

export default User;
