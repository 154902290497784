import { Tooltip } from "@mui/material";
import React from 'react';
import NotAssignCardIcon from "../../styles/svg/unassigned.svg";
import Avatar from "../../components/Avatar/index.js"
import { hasImage } from "../../utils/helpers";
const AvatarImage = ({ cardData, name, isPresent }) => {

    function getInitials(name) {
        const words = name?.trim()?.split(" ");
        const initials = words?.map((word) => word[0]?.toUpperCase())?.join("");
        return initials;
      }

  return (
    <>
      <Tooltip title={isPresent ? name : "Unassigned"}>
        {isPresent ? (
          <Avatar
            title={name}
            className={"card-avatar header-avatar"}
            user_image={cardData?.user_image}
            style={
              !hasImage(cardData?.user_image) && {
                bgcolor: cardData?.profile_color?.background,
                color: cardData?.profile_color?.text,
              }
            }
          >
            {getInitials(name)}
          </Avatar>
        ) : (
          <Avatar
          unassigned={true}
            className={"card-avatar header-avatar"}
            user_image={NotAssignCardIcon}
          />
        )}
      </Tooltip>
    </>
  );
};

export default AvatarImage;
