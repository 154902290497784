import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import AssignCard from "../../../../Kanban/AssignCard";
import {
  formatTimeFromNow,
  truncateText,
} from "../../../../../../../utils/helpers";
import { isAdmin } from "../../../../../../../utils/constants";
import EditTitle from "../../../../Kanban/EditTitle";
import { useCustomSearchParams } from "../../../../../../../hooks/useCustomSearchParams";
import { Tooltip } from "@mui/material";

const ListSubtasks = ({ card, boardKey }) => {
  const currentUser = useSelector((state) => state.users.logInUser);
  const queryClient = useQueryClient();
  const { id: boardId } = useParams();
  const [cardId, updateSearchParams] = useCustomSearchParams("card_id");
  const [isEditing, setIsEditing] = useState(false);
  const cachedData = queryClient.getQueryData(["boardName", boardId]);
  const tooltipName = `${card?.card?.assigned_to_user?.first_name || ""} ${
    card?.card?.assigned_to_user?.last_name || ""
  }`;
  const firstName = card?.card?.assigned_to_user?.first_name[0];
  const lastName = card?.card?.assigned_to_user?.last_name[0];
  const name = `${firstName || ""}${lastName || ""}`;
  const accessForAssignOrEditTitle =
    currentUser?.user_id == card?.card?.reporter ||
    currentUser?.user_id ==
      cachedData?.data?.data.responseData?.owner_user_id ||
    isAdmin();
  return (
    <div className="d-flex align-item-center subtask-container">
      <div className="d-flex width-100">
        <div className="d-flex align-items-center mr-3">
          <div className="cursor-pointer">
            <AssignCard
              tooltipName={tooltipName}
              project={card?.card}
              name={name}
              accessForAssign={accessForAssignOrEditTitle}
              cardId={card?.card?.card_id}
              isAssignable={true}
            />
          </div>
          <div className="ml-1 w-70">
            <p className="d-flex align-items-center cursor-pointer"  onClick={() => {
                  updateSearchParams("card_id", card?.card?.card_id);
                }}>
              <p
                className="card-id-number board-owner-avtar-click"
              >
                {boardKey}-{card?.card?.card_id}
              </p>
            </p>
            <p className="related_time">
              {formatTimeFromNow(card?.card?.date_created)}
            </p>
          </div>
        </div>
        <div onClick={() => setIsEditing(true)} className="pl-2 cursor-pointer">
          {/* <Tooltip className="card-title-text" title={card?.card?.title}>
            {truncateText(card?.card?.title, 60)}
          </Tooltip> */}
          <EditTitle
            project={card}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            subtask={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ListSubtasks;
