import * as React from "react";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { createSvgIcon } from "@mui/material/utils";
import { DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";

import addIcon from "../../../../../styles/svg/group-plus-icon.svg";
import Form from "./Form";
import { isAdmin } from "../../../../../utils/constants";
import { useResponsive } from "../../../../../hooks/useResponsive";

const emails = ["username@gmail.com", "user02@gmail.com"];

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function AddUserDialog(props) {
  const { onClose, selectedValue, open, boardName, users } = props;

  const isMobile = useResponsive("down", "md");
  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <>
      <React.Fragment>
        <BootstrapDialog
          fullWidth
          aria-labelledby="responsive-dialog-title"
          onClose={handleClose}
          open={open}
          sx={{
            height: "90vh",
            "& .MuiPaper-root": {
              borderRadius: "12px",
              maxWidth: "470px",
            },
          }}>
          <DialogTitle
            sx={{
              m: 0,
              p: isMobile ? "8px 12px !important" : 2,
              fontSize: isMobile && "16px",
              bgcolor: "rgba(242, 246, 254, 1)",
              fontWeight: isMobile ? "400" : "600",
              fontSize: "16px",
            }}
            id="responsive-dialog-title">
            Add user to {boardName?.data?.responseData?.board_name}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              padding: isMobile ? "0px !important" : "",
              right: isMobile ? "5px !important" : 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon />
          </IconButton>
          <DialogContent
            dividers
            className={isMobile ? "add-user-form" : "dialog-content-form"}>
            <Form
              onClose={handleClose}
              boardUsers={users}
              isMobile={isMobile}
            />
          </DialogContent>
        </BootstrapDialog>
      </React.Fragment>
    </>
  );
}

const PlusIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M12 4.5v15m7.5-7.5h-15"
    />
  </svg>,
  "Plus"
);

export default function AddUsers({ boardName, ownerId, users }) {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);
  const user = useSelector((state) => state.users.logInUser);
  const isMobile = useResponsive("down", "md");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  return (
    <div>
      {(isAdmin() || ownerId == user.user_id) &&
        (isMobile ? (
          <img src={addIcon} onClick={handleClickOpen} alt="add user" />
        ) : (
          <Button
            onClick={handleClickOpen}
            sx={{ padding: 0, minWidth: 0, paddingTop: "2px" }}>
            <Avatar
              sx={{
                bgcolor: "#1B3E61",
                cursor: "pointer",
                width: 32,
                height: 32,
              }}>
              <PlusIcon />
            </Avatar>
          </Button>
        ))}

      {open && (
        <AddUserDialog
          boardName={boardName}
          selectedValue={selectedValue}
          open={open}
          onClose={handleClose}
          users={users}
        />
      )}
    </div>
  );
}
