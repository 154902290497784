import { useState, useEffect } from "react";
import { localStorageInstance } from "../utils/localStorage";
import { getAPIBaseUrl } from "../utils/constants";

function ProtectedImage({ name,...rest }) {
  const [imageSrc, setImageSrc] = useState("");
  const token = localStorageInstance.getItem("token");
  const getUrl = getAPIBaseUrl() + '/' + name;

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(getUrl, {
          headers: {
            token,
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch image");
        }

        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
        setImageSrc(objectUrl);

        // Cleanup: Revoke the object URL when the component unmounts
        return () => URL.revokeObjectURL(objectUrl);
      } catch (error) {
        console.error("Error fetching the image:", error);
      }
    };

    fetchImage();
  }, [getUrl, token]);


  return (
    <img
      src={imageSrc}
      {...rest}
    />
  );
}

export default ProtectedImage;
