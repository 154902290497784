import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Button from "@mui/material/Button";

import downloadAttachmentIcon from "../../../../../../styles/svg/downloadAttachmentIcon.svg";
import "./slideShow.scss";
import { getAPIBaseUrl } from "../../../../../../utils/constants";
import ProtectedImage from "../../../../../../components/ProtectedImage";
const SlideShow = ({ slideImages, attachmentId, handleDownloadAttachment }) => {
  const [defaultIndex, setDefaultIndex] = useState(0);
  const getUrl = getAPIBaseUrl();

  useEffect(() => {
    // Find the index of the image with the matching attachmentId
    const index = slideImages.findIndex(
      (item) => item.card_attachment_id === attachmentId
    );
    if (index !== -1) {
      setDefaultIndex(index);
    } else {
      setDefaultIndex(0); // Default to the first image if no match is found
    }
  }, [slideImages, attachmentId]);

  return (
    <div className="carousel-container">
      <Carousel
        showArrows={true}
        showThumbs={false}
        useKeyboardArrows
        selectedItem={defaultIndex}
        autoPlay={false}
        transitionTime={0}
        autoFocus={true}>
        {slideImages.map((item) => {
          return (
            <div className="carousel-slide">
              {item.attachment_type.toLowerCase() === "jpg" ||
              item.attachment_type.toLowerCase() === "jpeg" ||
              item.attachment_type.toLowerCase() === "png" ? (
                <ProtectedImage
                  name={item.data}          
                />
              ) : (
                <div className="imageIsNotPresent">
                  <img src={downloadAttachmentIcon} alt="" />
                  <p>{item.attachment_name}</p>
                  {/* <p> {item.attachment.substring(0, 20)} </p> */}
                  <Button
                    onClick={() => {
                      handleDownloadAttachment(item.card_attachment_id);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "#1B3E61",
                        border: "none",
                      },
                    }}
                    variant="contained"
                  >
                    {`Download ${item.attachment_type}`}
                  </Button>
                </div>
              )}
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};
export default SlideShow;
// ReactDOM.render(<SlideShow slideImages={slideImages}/>, document.querySelector('.demo-carousel'));
