import { getAPIBaseUrl } from "./constants";
import { localStorageInstance } from "./localStorage";

export const handleDownloadAttachment = async (path,name,download,newWindow=false) => {
  const token = localStorageInstance.getItem("token");
  const getUrl = getAPIBaseUrl() + "/" + path;
  try {
    const response = await fetch(getUrl, {
      headers: {
        token
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch image for download");
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    newWindow ? download(url, name,"_blank") : download(url, name);
  } catch (error) {
    console.error("Error downloading the image:", error);
  }
};
