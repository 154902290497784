import {
  AvatarGroup,
  Box,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Avatar as Image } from "@mui/material";
import moment from "moment";

import Avatar from "../../../../../components/Avatar";
import AddUsers from "./AddUsers";
import { useBoardUsers } from "../../../../../utils/reactQuery/users";
import { createAvtarName } from "../../../../../utils/helpers";
import RemoveUser from "./RemoveUser";
import { filterUser } from "../../../../../redux/usersSlice";
import UsersIcon from "../../../../../styles/svg/users-icon.svg";
import { useResponsive } from "../../../../../hooks/useResponsive";
import UsersMobile from "./UsersMobile";

function Users({ boardName, boardId, ownerId, showAddUser = true }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const [users, setUsers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const filterUserId = searchParams.get("search");
  const isMobile = useResponsive("down", "md");

  const { isLoading, error, data, isError } = useBoardUsers(id);

  useEffect(() => {
    setUsers(
      data?.data?.data?.responseData ? data?.data?.data?.responseData : []
    );
  }, [isLoading, id, data]);

  const closeUserList = () => {
    setAnchorEl(null);
  };

  const filterUserById = (userId) => {
    dispatch(filterUser(userId));
    updateSearchParams("search", userId);
  };

  const updateSearchParams = (key, value) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(key, value);
    setSearchParams(newSearchParams);
  };
  const removeSearchParam = (key) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete(key);
    setSearchParams(newSearchParams);
  };

  const isLessUsers = users.length < 6 && users.length > 1 ? true : false;
  return (
    <>
      {isMobile ? (
        <div>
          <Stack direction={"row"} justifyContent={'space-between'}>
            <Typography color="#1B3E61" mb={1} fontWeight={600}>
              Select user
            </Typography>
            <AddUsers boardName={boardName} ownerId={ownerId} users={users} />
          </Stack>
          <Box maxHeight={165} overflow={"auto"} minHeight={100}>
            <UsersMobile users={users} />
          </Box>
        </div>
      ) : (
        <Stack className="align-items-center">
          <div
            className={
              isMobile ? "" : "d-flex justify-content-center align-items-center"
            }
          >
            {/* {filterUserId && (
            <Button
              size="small"
              sx={{ color: "#1b3e61" }}
              onClick={() => {
                removeSearchParam("search");
                dispatch(filterUser(null));
              }}
            >
              Clear
            </Button>
          )} */}
            <AvatarGroup
              total={users.length}
              // onClick={(event) => setAnchorEl(event.currentTarget)}
              renderSurplus={(surplus) => (
                <span onClick={(event) => setAnchorEl(event.currentTarget)}>
                  +{users.length - 4}
                </span>
              )}
              className={`avatar-group cursor-pointer ${"avatar-group-border"}`}
            >
              {users
                .sort((a, b) => {
                  const dateA = moment(a.last_seen);
                  const dateB = moment(b.last_seen);
                  return dateB - dateA;
                })
                .map((el) => (
                  <Avatar
                    key={`user-avtar-${el.user_id}`}
                    onClick={() => filterUserById(el.user_id)}
                    username={el.username}
                    user_image={el.user_image}
                    title={el.username}
                    className={`header-avatar ${
                      el.user_id == filterUserId ? "active-avtar" : ""
                    }`}
                    // style={
                    //   !hasImage(el.user_image) && {
                    //     bgcolor: el?.profile_color?.background,
                    //     color: el?.profile_color?.text,
                    //   }
                    // }
                  >
                    {createAvtarName(el.username)}
                  </Avatar>
                ))}
            </AvatarGroup>

            {isLessUsers && (
              <Tooltip title={"User List"}>
                <Image
                  onClick={(event) => setAnchorEl(event.currentTarget)}
                  src={UsersIcon}
                  key={"avatar-12343"}
                  className={"header-avatar cursor-pointer"}
                >
                  AS
                </Image>
              </Tooltip>
            )}

            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={closeUserList}
              MenuListProps={{
                "aria-labelledby": "epics-board-dropdown",
              }}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 14,
                    height: 14,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              className="assign-board-owner-list"
            >
              {users
                .sort((a, b) => {
                  const dateA = moment(a.last_seen);
                  const dateB = moment(b.last_seen);
                  return dateB - dateA;
                })
                .map((el) => {
                  return (
                    <RemoveUser
                      ownerId={ownerId}
                      user={el}
                      onClick={() => filterUserById(el.user_id)}
                      closeUserList={closeUserList}
                      isSelected={el.user_id == filterUserId}
                      removeSearchParam={removeSearchParam}
                    />
                  );
                })}
            </Menu>

            {showAddUser && (
              <AddUsers boardName={boardName} ownerId={ownerId} users={users} />
            )}
          </div>
        </Stack>
      )}
    </>
  );
}

export default Users;
