import React, { useCallback, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

import Loader from "./Loader";
import { useUserData } from "../utils/reactQuery/users";
import { localStorageInstance } from "../utils/localStorage";
import { user } from "../redux/usersSlice";
import toast from "react-hot-toast";

function LoginAsSuperAdmin({ children }) {
  const domain = window.location.hostname.split(".")[0];
  const token = Cookies.get(`${domain}_auth_admin_token`);
  const { data, isError,error } = useUserData(token);
  const dispatch = useDispatch();

  if (data && token) {
    const { user_data } = data?.data?.data?.responseData[0];
    localStorageInstance.clear();
    dispatch(user(user_data));
    localStorageInstance.setItem({
      key: "token",
      value: token,
    });
    localStorageInstance.setItem({
      key: "role",
      value: user_data.role,
    });
    localStorageInstance.setItem({
      key: "userId",
      value: user_data.user_id,
    });
    Cookies.remove(`${domain}_auth_admin_token`);
  }

  if (token && isError ){
    console.log(error);
    toast.error("Invalid Token");
    Cookies.remove(`${domain}_auth_admin_token`);
  }
    return token && !data && !isError ? (
      <div className="loader-center">
        <Loader color={"black"} />
      </div>
    ) : (
      children
    );
}

export default LoginAsSuperAdmin;
