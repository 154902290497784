import React, { useEffect, useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  Typography,
  useScrollTrigger,
} from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import User from "./User";
import {
  changeGroupExpand,
  setAllGroupUser,
  setGroupUser,
  setTeamGroupUser,
} from "../../../../redux/TaskGroupingSlice";
import GroupUser from "../../../../styles/svg/Team-group.svg";
import Status from "./Status";
import TeamFilter from "./TeamFilter";
import GlobalSearch from "../../../../components/Search";
import { searchCard } from "../../../../redux/SearchSlice";
import { useCustomSearchParams } from "../../../../hooks/useCustomSearchParams";
import { useUserWorkflowList } from "../../../../utils/reactQuery/workflows";
import { useSetMultipleParams } from "../../../../hooks/useSetMultipleParams";
import { useBoards } from "../../../../utils/reactQuery/boards";
import { setActiveBoards } from "../../../../redux/ActiveBoardSlice";
import { useResponsive } from "../../../../hooks/useResponsive";
import filterIcon from "../../../../styles/svg/epic-icon.svg";
import { useTeams } from "../../../../utils/reactQuery/teams";
import TeamUser from "../../Worklog/components/TeamUser";
import { useUsers } from "../../../../utils/reactQuery/users";

const AnimatedDrawerContent = styled(Box)(({ theme }) => ({
  width: 250,
  transition: "transform 1s ease-in-out",
}));

function Header() {
  const dispatch = useDispatch();
  const groupingUser = useSelector(
    (state) => state.groupingUser.allocationUsers
  );
  const [filterdUser, setFilterUser] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [workflowId, setWorkflowId] = useCustomSearchParams("workflow_id");
  const [boardId, setBoardId, removeBoard] = useCustomSearchParams("board_id");
  const [searchParam, setSearchParam, removeSearchParam] =
    useCustomSearchParams("txt");
  const [setMultipleSearchParams] = useSetMultipleParams();
  const { userWorkflows } = useUserWorkflowList();
  const [genericId, setGenericId] = useState(false);
  const isMobile = useResponsive("down", "md");
  const { isLoading, boards, isError } = useBoards();
  const trigger = useScrollTrigger();
  const [filterDrawer, setFilterDrawer] = useState(false);
  const { teams, isLoading: teamIsLoading } = useTeams();
  const userTeam = useSelector((state) => state.users.logInUser.team_id);
  const [searchParams, setSearchParams] = useSearchParams();
  const { users, isLoading: usersIsLoading } = useUsers();
  const [userParams, setUserParams] = useCustomSearchParams("uid");

  const setTeam = (users) => {
    dispatch(setTeamGroupUser(users));
  };
  useEffect(() => {
    dispatch(
      setActiveBoards(
        boards?.data?.data?.responseData
          .filter((el) => !el.is_archive)
          .sort(function (a, b) {
            return a.board_name.localeCompare(b.board_name);
          }) || []
      )
    );
  }, [boards]);

  useEffect(() => {
    if (!workflowId) {
      if (userWorkflows?.data?.data?.responseData[0]?.workflow_id) {
        setWorkflowId(
          "workflow_id",
          userWorkflows?.data?.data?.responseData[0]?.workflow_id
        );
      }
    }
  });

  useEffect(() => {
    setInputValue(searchParam || "");
    dispatch(searchCard(searchParam || ""));
  }, []);

  const clearFilters = () => {
    dispatch(setAllGroupUser([]));
  };

  const debouncedSearch = useRef(
    _.debounce((value) => {
      dispatch(searchCard(value));
      setSearchParam("txt", value);
    }, 300) // 300ms delay
  ).current;

  const handleChange = (event) => {
    const value = event.target.value.trimStart();
    setInputValue(value);
    debouncedSearch(value);
  };
  useEffect(() => {
    return () => {
      clearFilters();
    };
  }, []);

  const checkGenric = (id) => {
    const temp = userWorkflows?.data?.data?.responseData.filter((ele) => {
      return id == ele.workflow_id;
    });
    if (temp) {
      const isGeneric = temp[0]?.is_generic;
      setGenericId(isGeneric);
      setMultipleSearchParams([
        { name: "workflow_id", value: id },
        { name: "is_generic", value: isGeneric },
      ]);
    }
  };

  const handleBoardId = (boardId, workflowId) => {
    setMultipleSearchParams([
      { name: "board_id", value: boardId },
      { name: "workflow_id", value: workflowId },
    ]);
  };

  useEffect(() => {
    if (teams && users) {
      if (userParams && userParams[0]) {
        userParams?.split(",").forEach((element) => {
          dispatch(
            setGroupUser(
              users.data.data.responseData.find(
                (user) => user.user_id === element * 1
              )
            )
          );
        });
      } else if (!searchParams.get("user") && teams) {
        teams?.data.data.responseData
          .filter((ele) => ele.team_id == userTeam)?.[0]
          .users.forEach((element) => {
            dispatch(setGroupUser(element));
          });
      }
    }
  }, [teamIsLoading, usersIsLoading]);

  useEffect(() => {
    setFilterUser(
      groupingUser
        .map((item) => item.users)
        .flat()
        .filter((el) => el.active)
    );
  }, [groupingUser]);

  useEffect(() => {
    if (
      filterdUser &&
      filterdUser.length &&
      userParams?.split(",").length != filterdUser
    ) {
      setUserParams(
        "uid",
        filterdUser.map((el) => el.user_id)
      );
    }
  }, [filterdUser]);
  return (
    <div className="d-flex justify-content-space-between ">
      {!isMobile && <p className="board-heading-name">Assigned to Team</p>}
      {isMobile ? (
        <>
          <Box sx={{ height: "55px", visibility: "hidden" }}></Box>
          <Grid
            container
            xs={12}
            className="remove-container-width fixed-ui"
            sx={{
              position: "fixed !important",
              top: trigger ? "0px" : isMobile ? "51px" : "",
            }}
            justifyContent={"center"}
            pb={"18px !important"}
            pt={trigger ? "24px !important" : "20px !important"}
            alignItems={"center"}>
            <Grid
              item
              xs={6}
              md={12}
              lg={3}
              className="d-flex align-items-center">
              <Typography fontWeight={600}>Assigned to Team</Typography>
            </Grid>
            <Grid item xs={6} md={7} lg={9}>
              <Stack direction="row" justifyContent="end">
                <img
                  src={filterIcon}
                  alt="filter"
                  style={{ marginRight: "8px" }}
                  onClick={() => setFilterDrawer(true)}
                />
              </Stack>{" "}
            </Grid>
          </Grid>
        </>
      ) : (
        <div className="d-flex align-items-center ">
          <div className="d-flex align-items-center mr-2">
            <FormControl
              sx={{
                m: 1,
                minWidth: 120,
                "& .MuiInputLabel-shrink": {
                  top: "0px !important",
                },
              }}>
              <InputLabel
                id="demo-simple-select-helper-label"
                sx={{
                  top: "-9px",
                }}>
                Select Board
              </InputLabel>
              <Select
                // labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={boardId || ""}
                label="Select Board"
                placeholder="Select Board"
                sx={{
                  height: "38px",
                  width: "150px",
                  fontSize: "15px",
                  color: "#000",
                }}>
                <MenuItem
                  value={""}
                  key={"all-board"}
                  onClick={() => {
                    handleBoardId(
                      "",
                      userWorkflows?.data?.data?.responseData[0]?.workflow_id
                    );
                  }}>
                  All boards
                </MenuItem>
                {boards?.data?.data?.responseData
                  ?.filter((ele) => !ele.is_archive)
                  .sort((a, b) => a.board_name.localeCompare(b.board_name))
                  .map((ele, idx) => {
                    return (
                      <MenuItem
                        value={ele.board_id}
                        key={`board-list-${ele.board_id}`}
                        onClick={() =>
                          handleBoardId(ele.board_id, ele.workflow_id)
                        }>
                        {ele.board_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className="d-flex align-items-center mr-2">
            {/* <p className="filter-text">Select Workflow:</p> */}
            <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label" shrink>
                Select Workflow
              </InputLabel>
              <Select
                id="demo-simple-select"
                value={workflowId || ""}
                label="Select Workflow"
                sx={{
                  height: "38px",
                  width: "150px",
                  fontSize: "15px",
                  color: "#000",
                }}
                onChange={(event) =>
                  // setWorkflowId("workflow_id", event.target.value)
                  checkGenric(event.target.value)
                }>
                {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                  return (
                    <MenuItem value={ele.workflow_id}>
                      {ele.workflow_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
          {/* <User user={groupingUser.map((item) => item.users).flat()} /> */}
          {teams && <TeamUser user={filterdUser} team={teams} />}
          <Status />
          {/* {teams && <TeamFilter team={teams} />} */}
          <div style={{ marginLeft: "16px" }}>
            <GlobalSearch
              placeholder={"Search..."}
              searchText={inputValue}
              handleChange={handleChange}
            />
          </div>
        </div>
      )}
      {filterDrawer && (
        <Drawer
          open={filterDrawer}
          anchor="right"
          onClose={() => setFilterDrawer(false)}>
          <AnimatedDrawerContent
            style={{
              transform: filterDrawer ? "translateX(0)" : "translateX(100%)",
            }}>
            <Box p={1}>
              <div>
                <p className="filter-text">Select Workflow:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  {userWorkflows?.data?.data?.responseData?.map((ele, idx) => {
                    return (
                      <Typography
                        className={"workflow_steps"}
                        sx={{
                          backgroundColor:
                            ele.workflow_id == workflowId && "#C5DCF3",
                          cursor: "pointer",
                        }}
                        onClick={() => checkGenric(ele.workflow_id)}>
                        {ele.workflow_name}
                      </Typography>
                    );
                  })}
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select user:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  <User user={groupingUser.map((item) => item.users).flat()} />
                </div>
              </div>

              <div className="mt-4">
                <p className="filter-text">Select Team:</p>
                <div
                  style={{
                    maxHeight: "180px",
                    overflow: "auto",
                    marginTop: "8px",
                  }}>
                  {teams && (
                    <TeamFilter
                      team={teams}
                      user={groupingUser.map((item) => item?.users).flat()}
                    />
                  )}
                </div>
              </div>
            </Box>
          </AnimatedDrawerContent>
        </Drawer>
      )}
    </div>
  );
}

export default Header;
